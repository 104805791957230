<template>

  <div>
    <!-- 初始化地圖設定 -->
    <div id="myDiv">
      <l-map
          ref="myMap"
          id="map"
          :zoom="zoom"
          :center="center"
          :options="options"
          style="height: 83vh;"
          @ready="attachSidebar"
      >
        <!-- 載入圖資 -->
        <l-tile-layer :url="url" :attribution="attribution" />
        <!--營運商選單-->
        <l-control class="service-control" >
          <div style="float: right;">
            <el-select  style="border-color:#778899; border-style:solid;border-width: 1px;" v-model="listQuery.serviceId" clearable size="small" v-bind:placeholder="$t('AllOperator')" class="filter-item-mid" v-if="serviceList.length>0">
              <el-option v-for="item in serviceList" :key="item.id" :label="item.name + ' (' + item.code + ')'" :value="item.id" />
            </el-select>
          </div>
        </l-control>
      </l-map>
    </div>

<!-- 充電站搜尋控制項 Sidebar-->
    <div id="sidebar" class="leaflet-sidebar collapsed" style="margin-left: 10px;">
      <!-- Nav tabs -->
      <div class="leaflet-sidebar-tabs">
        <ul role="tablist"> <!-- top aligned tabs -->
          <li><a href="#home" role="tab"><i class="el-icon-search"></i></a></li>
<!--          <li class="disabled"><a href="#messages" role="tab"><i class="el-icon-user-solid"></i></a></li>-->
<!--          <li><a href="#profile" role="tab"><i class="el-icon-delete"></i></a></li>-->
        </ul>
      </div>

      <!-- Tab panes -->
      <div class="leaflet-sidebar-content">
        <div class="leaflet-sidebar-pane" id="home">
          <h1 class="leaflet-sidebar-header">
            充電站搜尋
            <div class="leaflet-sidebar-close"><i class="el-icon-caret-left"></i></div>
          </h1>
          <br/>
          <el-row><h2>點選列表中任一充電站，在地圖上進行定位</h2></el-row>
          <br/>
          <el-row>
            <el-input
                v-model="listQuery.name" placeholder="請輸入充電站名稱" prefix-icon="el-icon-search" clearable style="width: 98%;">
            </el-input>
          </el-row>
          <br/>
          <el-row>
            <el-card class="box-card" >
              <div slot="header" class="card-header">
                <span>充電站列表</span><span>充電站數：{{stationList.length}}</span>
              </div>
              <!--充電站列表-->
              <div style="height:100%;overflow: auto;margin-top: -5px;">
                <div v-for="item in stationList" :key="item.id" class="station-item" >
                  <el-button @click.native="mapSetMapCenter(item)" class="el-icon-location-information" type="success" plain
                             style="font-size: 1.2rem;color: Black;width: 98%;display: flex;justify-self: left;align-items: start;">
                    <span style="margin-left: 10px;font-size: 1rem;">{{item.name }}</span>
                  </el-button>

                </div>
              </div>

            </el-card>
          </el-row>

        </div>

      </div>
    </div>
    <!--充電站詳細資訊Drawer-->
    <el-drawer size="24%" :title=selectedStation.name :visible.sync="drawer" :modal=false :direction="direction" >
       <div v-if="stationInfoData.pointList.length <= 0"><span style="color: white;font-size: 1.2rem;">無充電樁詳細資訊</span></div>
       <div v-else>
         <el-card  v-for="(item,index) in stationInfoData.pointList" :key="index" style="height: 150px;margin: 20px;display: flex;">
           <div style="display: flex;line-height: 1;">
             <span style="margin-right: 10px; color: black;font-weight: bold;">充電樁:</span>
             <span :id="index+'_pointName'" style="color: #004B97;font-weight: bold;" v-html="checkNameLength(item.pointNameName)"></span>
           </div>
           <div class="box-header-point with-border"></div>
           <div style="height:100px;overflow:auto;">
             <el-row style="display: flex;line-height: 1;margin-bottom: 5px;" v-for="(connector,index) in item.connectorList" :key="index">
               <span style="margin-right: 10px; color: black;font-weight: bold;">充電槍{{connector.connectorID}}</span>
               <span style="font-weight: bold;">狀態：{{connectorStateTrans[connector.connectorState]}}</span>
             </el-row>
           </div>
         </el-card>
       </div>


<!--      <span>我来啦!</span>-->
    </el-drawer>

  </div>
</template>

<script>
import L from "leaflet";
import "leaflet-sidebar-v2";
import "leaflet-sidebar-v2/css/leaflet-sidebar.css";
import {MarkerClusterGroup} from "leaflet.markercluster";//必要引入


export default {
  name: "DashboardMap",
  data() {
    return {
      connectorStateTrans:{
        Available: '待機',
        Suspended: '占用中',
        Charging: '充電中',
        Finish: '充電完成',
        Disconnect: '離線',
        Error: '異常'
      },
      drawer: false,
      direction: 'rtl',
      listQuery: {
        serviceId: undefined,
        enabled: true,
        externalService: true,
        name: undefined,
        // page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
        // size: 10,
        // sort: 'id,Desc'
      },
      stationInfoQuery: {
        serviceId: undefined,
        stationId: undefined
      },
      searchInput: undefined,
      serviceList: [], //營運商
      stationList:[], //充電站
      activeName: 'dashboard-map',
      // 充電站-模擬資料
      data: [
        { id: 91, name: "夢時代購物中心", local: [22.595153, 120.306923], serviceId:99 },
        { id: 92, name: "漢神百貨", local: [22.61942, 120.296386], serviceId:99 },
        { id: 93, name: "漢神巨蛋", local: [22.669603, 120.302288], serviceId:99 },
        { id: 94, name: "大統百貨", local: [22.630748, 120.318033], serviceId:99 },
        { id: 95, name: "夢時代購物中心2", local: [22.595248, 120.306746], serviceId:99 },
        { id: 96, name: "漢神百貨2", local: [22.61657, 120.296496], serviceId:99 },
        { id: 97, name: "漢神巨蛋2", local: [22.669526, 120.302366], serviceId:99 },
        { id: 98, name: "大統百貨2", local: [22.630660, 120.318177], serviceId:99 }
      ],
      //充電站詳細資料-模擬資料
      stationInfoData:{
        stationID: 1,
        stationName: "TL大安站",
        pointList: [
          {
            chargeID: "bf6f7aa1-f377-4522-9dcd-31535b285434",
            pointNameName: "台達電 AC mini plus (1)",
            connectorList: [
              {
                connectorID: 1,
                connectorState: "Disconnect"
              },
              {
                connectorID: 2,
                connectorState: "Disconnect"
              }
            ]
          },
          {
            chargeID: "bf6f7aa1-f377-4522-9dcd-31535b285434",
            pointNameName: "CP simulator1",
            connectorList: [
              {
                connectorID: 1,
                connectorState: "Disconnect"
              },
              {
                connectorID: 2,
                connectorState: "Disconnect"
              }
            ]
          },
          {
            chargeID: "eb282fc3-3112-45a2-8b7d-f3f17709daac",
            pointNameName: "CP simulator2",
            connectorList: [
              {
                connectorID: 1,
                connectorState: "Disconnect"
              }
            ]
          },
          {
            chargeID: "123456789abcdefghijk123456789",
            pointNameName: "起而行充電樁",
            connectorList: [
              {
                connectorID: 1,
                connectorState: "Available"
              }
            ]
          }
        ]
      },
      result: [],
      temp_deviceHashTable : {},//存放新進的充電站marker
      selectedStation: {
        name:"",
      },
      zoom: 13,
      center: [22.612961, 120.304167],
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution: `© <a href="http://osm.org/copyright">OpenStreetMap</a> contributors`,
      options: {
        zoomControl: true
      },
      icon: {
        type: {
          custom:"charging.png" , //充電站icon
          black:
              "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-black.png",
          gold:
              "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-gold.png"
        },
        shadowUrl:
            "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [40, 40],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      },
      //客製化icon元件
      customIcon: new window.L.Icon({
        iconUrl: "charging.png",
        iconSize: [40, 40],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
        tooltipAnchor: [10, -30], // top right

      }),
    };
  },
  watch:{

    'listQuery.serviceId': function () {
      this.getChargingStations();
    },
    'listQuery.name': function() {
      this.getChargingStations();
    },
  },
  created() {
    this.getServiceOptions();
    this.getChargingStations();
  },
  mounted() {
    // 等地圖創建後執行
    this.$nextTick(() => {
      console.log('nexTick');

      var ev = this;
      //設定圖層切換控制項
      var baseLayers = {
        "OpenStreetMap": window.L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 19,
          attribution: 'Map data: © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: © <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
        }).addTo(this.$refs.myMap.mapObject),
        "Esri 衛星圖" :  window.L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
          attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
        })
      };
      window.L.control.layers(baseLayers, {}, { position: "topleft" }).addTo(this.$refs.myMap.mapObject);


      // var tr = document.querySelector('.el-icon-d-arrow-right')
      // L.DomEvent.on(tr,'click',(e)=>console.log('click'));

    });

  },
  methods:{
    //充電站定位及顯示充電站詳細資訊(樁數/槍數/槍狀態)
    mapSetMapCenter(station_item){
      let ev = this;
      let marker = this.temp_deviceHashTable[station_item.id];
      let myMap = this.$refs.myMap.mapObject;
      //20240524 by taiyu , in-order-to bind the More-Info icon On-Click event when Popup opening, we close previous PopUp first before showing next one
      // 目的: 顯示下一個充電站PopUp前，先將上一次的popup關閉，這樣下次PopUp open時才能Bind More-Info icon click event
      myMap.closePopup();

      this.selectedStation = marker;

      if(myMap !== null && myMap !== undefined)
      {

        if(marker !== null)
        {
          var spinner2 = '<div><span class="el-icon-loading"></span> Loading...</div>';
          var html = '';
          var deviceNameElement = '';
          var selectedMarker = marker;
          //檢查若設備名稱過長,進行縮寫
          if(selectedMarker.name.length > 10)
          {
            deviceNameElement = '<span title="'+selectedMarker.name+'">'+selectedMarker.name.substring(0,8).replace(/\'/g,"\"")+"..."+'</span>';
          }
          else
          {
            deviceNameElement = '<span title="'+selectedMarker.name+'">'+selectedMarker.name+'</span>';
          }

          if (selectedMarker._popup != undefined) {
            //selectedMarker._popup.close();
            selectedMarker.unbindPopup();
          }

          //先顯示Loading...
          var popup = window.L.popup({
            className : 'custom'
          }).setLatLng(new L.LatLng(parseFloat(selectedMarker.lat)+parseFloat(0.0003),parseFloat(selectedMarker.lon)+parseFloat(0.0001)))
            .setContent(spinner2).openOn(myMap);


          //selectedMarker.bindPopup(popup).openPopup();

          //取得充電站詳細資訊
          this.stationInfoQuery.serviceId = selectedMarker.serviceId;
          this.stationInfoQuery.stationId = selectedMarker.id;
          this.axios.get('/api/v1/map/getStationInfo',{params: this.stationInfoQuery}).then(res => {
            //selectedMarker.closePopup();
            popup.close();
            let result = [];

            console.log(res);
            result = res.data
            console.log("getStationInfo result", result);

            //地址icon
            html += '<h4 style="display: flex;justify-content: end;margin-top: 10px;"><i class="el-icon-s-home tooltip"><span class="tooltiptext" >'+selectedMarker.address+'</span></i></h4>';
            //充電站名稱
            html += '<h3 class="sensorname">' + deviceNameElement + '</h3>';
            //分隔線
            html += '<div class="box-header with-border"></div>';
            //充電樁數
            html += '<h4><span>充電樁數：' + result[0].pointNum + '</span></h4>';
            //充電槍數
            html += '<h4 style="margin-top: 10px;"><span>充電槍數：' + result[0].connectorNum + '</span></h4>';
            html += '<h4><span>待機中：' + result[0].availableNum + '</span>&#8194&#8194' + '<span>占用中：' + result[0].prepareNum + '</span>&#8194&#8194' + '<span>充電中：' + result[0].chargingNum + '</sapn></h4>'
            html += '<h4><span>充電完成：' + result[0].finishNum + '</span>&#8194&#8194' + '<span>離線中：' + result[0].outlineNum + '</span>&#8194&#8194' + '<span>異常：' + result[0].errorNum + '</sapn></h4>'
            html += '<h4 style="display: flex;justify-content: end;margin-top: 20px;"><i id="showMore" class="el-icon-d-arrow-right tooltip" style="cursor: pointer;"><span class="tooltiptext" >詳細資訊</span></i></h4>'
            //bind Popup內容裡的「詳細資訊」按鈕onClick method
            // this.$refs.myMap.mapObject.once('popupopen', function (){
            //   console.log('bind PopUpOpen event')
            //   document.getElementById('showMore').onclick = function () {
            //     //呼叫取得充電站詳細資訊API
            //     ev.showStationDetail(ev.stationInfoQuery.serviceId,ev.stationInfoQuery.stationId,result[0].stationName);
            //   };
            // })

            // html += '<h4><span>充電樁：'+ result[0].pointNum + '</span>&#8194&#8194' +'<span>充電槍:'+result[0].connectorNum+'</span></h4>'
            //selectedMarker.bindPopup(html, {className: 'custom'});
            //selectedMarker.openPopup();

            popup.setContent(html).openOn(myMap);
            //bind Popup內容裡的「詳細資訊」按鈕onClick method,需擺在popup.setContent(html)後面
            document.getElementById('showMore').onclick = function () {
                  //呼叫取得充電站詳細資訊API
                  console.log('popup click event');
                  ev.showStationDetail(ev.stationInfoQuery.serviceId,ev.stationInfoQuery.stationId,result[0].stationName);
                };
            //關閉popup時順便清空content,強迫下一次產生content時會bind on-click event
            popup.once('remove', function() {
              popup.setContent('<div></div>');
              //Your code here
              console.log('popup '+ popup.getContent() +'is removed');
            });


            //移動地圖至以marker為中心點位置
            myMap.setView([marker.lat, marker.lon], 17);
          });

          //調整popup position,設定顯示在marker適當位置
          /*
          var lat = (station_item.local[0]);
          lat = parseFloat(lat)+parseFloat(0.0005);
          var lon = station_item.local[1];
          lon = parseFloat(lon)+parseFloat(0.0003);
          var popup_latlon = window.L.latLng(lat,lon);
          var spinner2 = '<div><span class="el-icon-loading"></span> Loading...</div>';
          //顯示popup
          var popup = window.L.popup({
              className : 'custom'
            })
              .setLatLng(popup_latlon)
              .setContent(marker.name)
              .openOn(myMap);
            */




        }
      }

    },
    clearMap() {
      //console.log("clearMap is called");
      /*
      * 先清空map, 注意:map有加入的layer型別可能是L.LayerGroup(deviceMarkers是markerClusterGroup型別)或是
      * 單個marker(marker是L.Marker型別)，所以都要將之remove，但還有一個圖層控制項類別(即 map tile, L.control.layers)，這個不能刪除，
      * 否則整個地圖畫面都是灰色的沒有任何tile，所以要判斷layer型別
      */
      let ev = this;
      let map = this.$refs.myMap.mapObject;
      map.closePopup();//關閉Popup
      map.eachLayer(function(layer){

        if(layer instanceof window.L.LayerGroup || layer instanceof window.L.Marker)
        {
          map.removeLayer(layer);
          //console.log("myMap.removeLayer is called");
        }

      });


    },
    getServiceOptions() {
      this.listLoading = true
      this.axios.get('/api/v1/common/service/enabled').then(res => {
        this.serviceList = res.data
      })
    },
    getChargingStations() {
      var ev = this;
      this.listLoading = true;

      this.axios.get('/api/v1/map/getStationByServiceId',{params: this.listQuery}).then(res => {
        let result = [];
        this.stationList = [];
        console.log(res);
        result = res.data
        console.log("getStationByServiceId result",result);
        result.forEach((_item) => {

          var item=  { id: _item.stationId, name: _item.stationName, local: [_item.lat, _item.lon], serviceId: _item.serviceId, address: _item.address };
          this.stationList.push(item);
        });

        //加入模擬資料
        /*
        this.data.forEach((_item) => {
          this.stationList.push(_item);
        });*/

        this.showStationMarkersWithCluster(this.stationList);

      })

    },
    markerClick(event){
      var selectedMarker = event.target;
      this.showStationInfo(selectedMarker);
    },
    showStationInfo(marker){
      console.log('showStationInfo',marker);
      var ev = this;
      let myMap = this.$refs.myMap.mapObject;
      //20240524 by taiyu , in-order-to bind the More-Info icon On-Click event when Popup opening, we close previous PopUp first before showing next one
      // 目的: 顯示下一個充電站PopUp前，先將上一次的popup關閉，這樣下次PopUp open時才能Bind More-Info icon click event
      myMap.closePopup();

      var spinner2 = '<div><span class="el-icon-loading"></span> Loading...</div>';
      var html = '';
      var deviceNameElement = '';
      var selectedMarker = marker;
      this.selectedStation = marker;


      var startBtn = L.DomUtil.create('i', 'el-icon-d-arrow-right tooltip');
      L.DomEvent.on(startBtn, 'click', () => {
        alert("toto");
      });

      //檢查若設備名稱過長,進行縮寫
      if(selectedMarker.name.length > 10)
      {
        deviceNameElement = '<span title="'+selectedMarker.name+'">'+selectedMarker.name.substring(0,8).replace(/\'/g,"\"")+"..."+'</span>';
        //selectedMarker.name = selectedMarker.name.substring(0,8).replace(/\'/g,"\"")+"...";
      }
      else
      {
        deviceNameElement = '<span title="'+selectedMarker.name+'">'+selectedMarker.name+'</span>';
      }

      if (selectedMarker._popup != undefined) {
        selectedMarker.unbindPopup();
      }

      var popup = window.L.popup({
        className : 'custom'
      })
          .setLatLng(new L.LatLng(parseFloat(selectedMarker.lat)+parseFloat(0.065),parseFloat(selectedMarker.lon)+parseFloat(0.033)))
          .setContent(spinner2);
      // .openOn(this.$refs.myMap.mapObject);
      selectedMarker.bindPopup(popup).openPopup();

      this.stationInfoQuery.serviceId = selectedMarker.serviceId;
      this.stationInfoQuery.stationId = selectedMarker.id;
      this.axios.get('/api/v1/map/getStationInfo',{params: this.stationInfoQuery}).then(res => {
        //selectedMarker.closePopup();
        popup.close();
        let result = [];

        console.log(res);
        result = res.data
        console.log("getStationInfo result", result);

        //地址icon
        html += '<h4 style="display: flex;justify-content: end;margin-top: 10px;"><i class="el-icon-s-home tooltip"><span class="tooltiptext" >'+selectedMarker.address+'</span></i></h4>';
        //充電站名稱
        html += '<h3 class="sensorname">' + deviceNameElement + '</h3>';
        //分隔線
        html += '<div class="box-header with-border"></div>';
        //充電樁數
        html += '<h4><span>充電樁數：' + result[0].pointNum + '</span></h4>';
        //充電槍數
        html += '<h4 style="margin-top: 10px;"><span>充電槍數：' + result[0].connectorNum + '</span></h4>';
        html += '<h4><span>待機中：' + result[0].availableNum + '</span>&#8194&#8194' + '<span>占用中：' + result[0].prepareNum + '</span>&#8194&#8194' + '<span>充電中：' + result[0].chargingNum + '</sapn></h4>'
        html += '<h4><span>充電完成：' + result[0].finishNum + '</span>&#8194&#8194' + '<span>離線中：' + result[0].outlineNum + '</span>&#8194&#8194' + '<span>異常：' + result[0].errorNum + '</sapn></h4>'
        html += '<h4 style="display: flex;justify-content: end;margin-top: 20px;"><i id="showMore" class="el-icon-d-arrow-right tooltip" style="cursor: pointer;"><span class="tooltiptext" >詳細資訊</span></i></h4>'
        //bind Popup內容裡的「詳細資訊」按鈕onClick method
        // this.$refs.myMap.mapObject.once('popupopen', function (){
        //   document.getElementById('showMore').onclick = function () {
        //     //呼叫取得充電站詳細資訊API
        //     ev.showStationDetail(ev.stationInfoQuery.serviceId,ev.stationInfoQuery.stationId,result[0].stationName);
        //   };
        // });

        // selectedMarker.bindPopup(html, {className: 'custom'});
        // selectedMarker.openPopup();
        popup.setContent(html).openOn(myMap);
        //bind Popup內容裡的「詳細資訊」按鈕onClick method,需擺在popup.setContent(html)後面
        document.getElementById('showMore').onclick = function () {
          //呼叫取得充電站詳細資訊API
          console.log('popup click event');
          ev.showStationDetail(ev.stationInfoQuery.serviceId,ev.stationInfoQuery.stationId,result[0].stationName);
        };
        //關閉popup時順便清空content,強迫下一次產生content時會bind on-click event
        popup.once('remove', function() {
          popup.setContent('<div></div>');
          //Your code here
          console.log('popup '+ popup.getContent() +'is removed');
        });

      });
    },
    showStationMarkersWithCluster(stationDataList){
      console.log("showStationMarkersWithCluster",stationDataList);
      var ev = this;
      this.clearMap();

      const markers = L.markerClusterGroup();
      // markers.on('clusterclick', function (a) {
      //   var latLngBounds = a.layer.getBounds();
      //   this.$refs.myMap.fitBounds(latLngBounds);
      // });
      //將充電站marker標示在地圖上並且zoom to fit the bound of all stations
      if(stationDataList.length > 0) {
        var group = new window.L.featureGroup();// for get the bound of all stations
        stationDataList.forEach((item) => {
          const title = item.name;
          var marker = new window.L.Marker(new window.L.LatLng(item.local[0], item.local[1]),{icon:ev.customIcon});
          marker.id = item.id;
          marker.name = item.name;
          marker.lat = item.local[0];
          marker.lon = item.local[1];
          marker.serviceId = item.serviceId;
          marker.address = item.address;

          marker.on('click', ev.markerClick);
          marker.bindPopup(title);
          ev.temp_deviceHashTable[item.id] = marker;
          markers.addLayer(marker);
          // var marker = new window.L.Marker(new window.L.LatLng(item.local[0], item.local[1]),{icon:ev.customIcon}).addTo(ev.$refs.myMap.mapObject);
          marker.bindTooltip(item.name,{ direction: 'top' })
          group.addLayer(marker);
        });
        this.$refs.myMap.mapObject.addLayer(markers);
        this.$refs.myMap.fitBounds(group.getBounds());

      }
      else {

        this.$message({
          type:'info',
          message: '無任何充電站'
        })

      }

    },
    attachSidebar(mapObject) {

      const sidebar = window.L.control.sidebar({
        autopan: false, // whether to maintain the centered map point when opening the sidebar
        closeButton: true, // whether t add a close button to the panes
        container: "sidebar", // the DOM container or #ID of a predefined sidebar container that should be used
        position: "left" // left or right
      });
      sidebar.addTo(mapObject);

    },
    showStationDetail(serviceId, stationId, stationName){
      let self = this;
      console.log("showStationDetail:"+serviceId+","+stationId+",stationName="+stationName);
      this.axios.get('/api/v1/map/getStationInfoDetail',{params: this.stationInfoQuery}).then(res => {

        console.log(res);
        self.stationInfoData= res.data
        console.log("getStationInfoDetail", self.stationInfoData);
        //document.getElementById("pointName").innerHTML(self.checkNameLength("台達電 AC mini plus"))
        this.drawer = true;
      });
    },
    checkNameLength(name)
    {
      let modifiedName = name;
      //檢查若設備名稱過長,進行縮寫
      if(name.length > 20)
      {
        modifiedName = '<span title="'+name+'">'+name.substring(0,17).replace(/\'/g,"\"")+"..."+'</span>';
        //selectedMarker.name = selectedMarker.name.substring(0,8).replace(/\'/g,"\"")+"...";
      }
      else
      {
        modifiedName = '<span title="'+name+'">'+name+'</span>';
      }
      return modifiedName;
    }
  }
}
</script>

<style>

.chargingPointClass{
  background: url("~@/assets/icons/img/charging.png") no-repeat;
}
/* css to customize Leaflet Popup styles  */
.custom .leaflet-popup-tip, .custom .leaflet-popup-content-wrapper {
  background: #2c3e50;
  /*background:#87CEFA;*/
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  border-radius: 5px;
  background-color: rgba(44, 62, 80, 0.9);
  padding: 10px;
}

/* css to customize Leaflet Popup styles  */
.custom .leaflet-popup-content {
  margin: 20px 20px;
  /*line-height: 2.2rem;*/
  font-family: 敺株�迤暺��;
  min-width:150px;
}

/* css to customize station name styles  */
.sensorname{
  min-width: 150px;
  text-overflow:ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  margin-bottom: 5px;
}

.box-header{
  border-bottom: 1px solid #f4f4f4;
  margin-bottom: 5px;
}

.box-header-point{
  border-bottom: 1px solid SlateGrey;
  margin-bottom: 20px;
}

.tooltip {
  position: relative;
  display: inline-block;
  /*border-bottom: 1px dotted black;*/
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: fit-content;
  font-size: 0.9rem;
  /*background-color: #555;*/
  background-color: rgba(44, 62, 80, 0.9);
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.el-drawer.rtl{
  color: white;
  font-size: 1.2rem;
  background: #222d32;
}

.el-drawer__body{
  background: #222d32;
}

.el-drawer__header{
  font-size: 1.2rem;
  color: #FFFFFF;
  display: flex;

}

span[role=heading]{
  display: flex;
  font-size: 1.4rem;
}

.leaflet-container a.leaflet-popup-close-button {
  color: white;
}

</style>
<style lang="scss" scoped>
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

/*營運商下拉式選單css*/
.service-control {
  /*background: #fff;*/
  padding: 0 0.5em;
  /*border: 1px solid #aaa;*/
  /*border-radius: 0.1em;*/
}

/*客製化列表控制項item icon*/
.el-icon-my-charging{
  background: url("~@/assets/icons/img/charging.png") no-repeat;
  background-size: 80%;
  width: 40px;
  height: 40px;

}

/*充電站列表Card csss*/
.el-card ::v-deep .el-card__header{
  background-color: #3c8dbc;
  color:white;
  border: 1px solid #3c8dbc;
}
/*充電站列表Card csss*/
.el-card ::v-deep .el-card__body{
  border: 1px solid #3c8dbc;
  height: 51vh;
  width: 100%;
}

/*充電站列表Card Header csss*/
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
}

/*充電站列表Card item csss*/
.station-item {
  margin-bottom: 0px;
  margin-top: 10px;
  width: 98%;
}
/*充電站列表Card custom csss*/
.box-card{
  width: 100%;
  height: 90%;
}



/*.clearfix:before,*/
/*.clearfix:after {*/
/*  display: table;*/
/*  content: "";*/
/*}*/
/*.clearfix:after {*/
/*  clear: both*/
/*}*/

/*.box-card {*/
/*  width: 380px;*/
/*}*/

.leaflet-sidebar {
  position: absolute;
  height: 98%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  z-index: 2000;
  margin-right: 20px;
}
/*.leaflet-sidebar.left {*/
/*  left: -1000px; !*has to be larger than any @media widths*!*/
/*  transition: left 0.5s, width 0.5s;*/
/*  padding-right: 0; }*/
/*.leaflet-sidebar.left.visible {*/
/*  left: 0; }*/
/*.leaflet-sidebar.right {*/
/*  right: -1000px; !*has to be larger than any @media widths*!*/
/*  transition: right 0.5s, width 0.5s;*/
/*  padding-left: 0; }*/
/*.leaflet-sidebar.right.visible {*/
/*  right: 0; }*/
/*.leaflet-sidebar > .leaflet-control {*/
/*  height: 100%;*/
/*  width: 100%;*/
/*  overflow: auto;*/
/*  -webkit-overflow-scrolling: touch;*/
/*  -webkit-box-sizing: border-box;*/
/*  -moz-box-sizing: border-box;*/
/*  box-sizing: border-box;*/
/*  padding: 8px 24px;*/
/*  font-size: 1.1em;*/
/*  background: white; background: rgba(255,255,255,0.75);*/
/*  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.65);*/
/*  -webkit-border-radius: 4px;*/
/*  border-radius: 4px; }*/
/*.leaflet-touch .leaflet-sidebar > .leaflet-control {*/
/*  box-shadow: none;*/
/*  border: 2px solid rgba(0, 0, 0, 0.2);*/
/*  background-clip: padding-box; }*/
/*@media (max-width: 767px) {*/
/*  .leaflet-sidebar {*/
/*    width: 100%;*/
/*    padding: 0; }*/
/*  .leaflet-sidebar.left.visible ~ .leaflet-left {*/
/*    left: 100%; }*/
/*  .leaflet-sidebar.right.visible ~ .leaflet-right {*/
/*    right: 100%; }*/
/*  .leaflet-sidebar.left {*/
/*    left: -100%; }*/
/*  .leaflet-sidebar.left.visible {*/
/*    left: 0; }*/
/*  .leaflet-sidebar.right {*/
/*    right: -100%; }*/
/*  .leaflet-sidebar.right.visible {*/
/*    right: 0; }*/
/*  .leaflet-sidebar > .leaflet-control {*/
/*    box-shadow: none;*/
/*    -webkit-border-radius: 0;*/
/*    border-radius: 0; }*/
/*  .leaflet-touch .leaflet-sidebar > .leaflet-control {*/
/*    border: 0; } }*/
/*@media (min-width: 768px) and (max-width: 991px) {*/
/*  .leaflet-sidebar {*/
/*    width: 600px; } !*make bigger here*!*/
/*  .leaflet-sidebar.left.visible ~ .leaflet-left {*/
/*    left: 600px; } !*make bigger here (move original map control)*!*/
/*  .leaflet-sidebar.right.visible ~ .leaflet-right {*/
/*    right: 600px; } } !*make bigger here*!*/
/*@media (min-width: 992px) and (max-width: 1199px) {*/
/*  .leaflet-sidebar {*/
/*    width: 800px; } !*make bigger here*!*/
/*  .leaflet-sidebar.left.visible ~ .leaflet-left {*/
/*    left: 800px; } !*make bigger here (move original map control)*!*/
/*  .leaflet-sidebar.right.visible ~ .leaflet-right {*/
/*    right: 800px; } } !*make bigger here*!*/
/*@media (min-width: 1200px) {*/
/*  .leaflet-sidebar {*/
/*    width: 920px; } !*make bigger here*!*/
/*  .leaflet-sidebar.left.visible ~ .leaflet-left {*/
/*    left: 920px; } !*make bigger here (move original map control)*!*/
/*  .leaflet-sidebar.right.visible ~ .leaflet-right {*/
/*    right: 920px; } } !*make bigger here*!*/
/*.leaflet-sidebar .close {*/
/*  position: absolute;*/
/*  right: 20px;*/
/*  top: 20px;*/
/*  width: 31px;*/
/*  height: 31px;*/
/*  color: #333;*/
/*  font-size: 25px;*/
/*  line-height: 1em;*/
/*  text-align: center;*/
/*  -webkit-border-radius: 16px;*/
/*  border-radius: 16px;*/
/*  cursor: pointer;*/
/*  z-index: 8; }*/

/*.leaflet-left {*/
/*  transition: left 0.5s; }*/

/*.leaflet-right {*/
/*  transition: right 0.5s; }*/

/*.form-control{*/
/*  margin: 30px !important;*/
/*}*/
</style>
