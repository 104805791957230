<template>
  <div>
<!--    <keep-alive><Dashboard v-if="activeName=='dashboard'"/></keep-alive>-->
<!--    <el-row>-->
<!--      <div style="float: right;z-index: 1000;">-->
<!--        <el-select transfer="true" :popper-append-to-body="false" v-model="listQuery.serviceId" clearable size="small" v-bind:placeholder="$t('AllOperator')" class="filter-item-mid" v-if="serviceList.length>0">-->
<!--          <el-option v-for="item in serviceList" :key="item.id" :label="item.name + ' (' + item.code + ')'" :value="item.id"-->
<!--            @click.native="clickme()"-->
<!--          />-->
<!--        </el-select>-->
<!--      </div>-->
<!--    </el-row>-->

    <el-tabs id="tabs" v-model="activeName" type="border-card" style="z-index: 100;">
      <el-tab-pane label="營運總覽" name="dashboard"><keep-alive><Dashboard v-if="activeName=='dashboard'"/></keep-alive></el-tab-pane>
      <el-tab-pane id="dashboard-map-pane" label="地圖模式" name="dashboard-map"><keep-alive><DashboardMap v-if="activeName=='dashboard-map'"/></keep-alive></el-tab-pane>
<!--      <el-tab-pane label="標案進度儀表板" name="contractDashboard"><keep-alive><ContractDashboard v-if="activeName=='contractDashboard'"/></keep-alive></el-tab-pane>-->
    </el-tabs>

  </div>
</template>

<script>
import Dashboard from './Dashboard.vue'
import DashboardMap from './DashboardMap'
//import BusinessDashboard from './BusinessDashboard.vue'
//import ContractDashboard from './ContractDashboard.vue'

export default {
  // 儀錶板
  name: 'DashboardIndex',
  components: {
   Dashboard,
   DashboardMap
    //BusinessDashboard,
    //ContractDashboard
  },
  data() {
    return {
      activeName: 'dashboard',
      listQuery: {
        type: 'general',
        enabled: true,
        name: undefined,
        serviceId: undefined,
        page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
        size: 10
      },
      serviceList: [],
    };
  },
  mounted() {


  },
  created() {
    this.getServiceOptions();
  },
  watch:{
    'activeName': function (value) {

      console.log("value",value);
      if(value == 'dashboard-map' && this.$store.state.sidebar.open)
      {
        this.$store.dispatch('toggleSidebar');
      }

    },
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    getServiceOptions() {
      this.listLoading = true
      this.axios.get('/api/v1/common/service/enabled').then(res => {
        this.serviceList = res.data
      })
    },
},

}
</script>

<style>
/*.el-tab-pane{*/
/*  position: relative;*/
/*  margin: 0px;*/
/*}*/
</style>
